<template>
  <div class="zamer-okna">
    <div class="container zamer-okna">
      <div class="zamer-content">

        <img class="zamer-okna-image" src="../../assets/windows/unkle-master.jpg" alt="">


        <h4>Подоконники, отливы, откосы - тоже наша забота.  </h4>


      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "OtlivOtkos"
}
</script>

<style scoped>
.zamer-okna{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 2rem;
}
.zamer-content{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

}
.zamer-okna-image{
  width: 200px;
  height: auto;
}
</style>