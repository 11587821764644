<template>
  <div class="zamer-okna">
    <div class="container zamer-okna">
      <div class="zamer-content">

        <img class="zamer-okna-image" src="../../assets/windows/zamer-okna.jpg" alt="">


        <h4>Устанавливаем окна по ГОСТам и СНИПам, опыт наших работников в среднем более 10 лет. </h4>


      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "UstanovkaOkna"
}
</script>

<style scoped>
.zamer-okna{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 2rem;
}
.zamer-content{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.zamer-okna-image{
  width: 200px;
  height: auto;
}
</style>