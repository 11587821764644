<template>
  <div class="door-inside-series">
    <BackToSeries style="padding-top: 2rem"/>
    <h2 class="door-inside-series-title">Серия Стандарт</h2>
    <h4 class="door-inside-series-subtitle">Серия стандарт - это проверенная временем класика</h4>
    <div class="container door-series-container">
      <router-link class="door-inside-series-link"  :to="{name:'standart_01'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_1.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 01</h5>
          <h5 style="text-align: center; color: crimson">от 78 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_02'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_2.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 02</h5>
          <h5 style="text-align: center; color: crimson">от 131 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_04'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_4.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 04</h5>
          <h5 style="text-align: center; color: crimson">от 113 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_05'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_5.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 05</h5>
          <h5 style="text-align: center; color: crimson">от 103 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_06'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_6.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 06</h5>
          <h5 style="text-align: center; color: crimson">от 103 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_07'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_7.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 07</h5>
          <h5 style="text-align: center; color: crimson">от 118 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_09'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_9.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 09</h5>
          <h5 style="text-align: center; color: crimson">от 103 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_10'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_10.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 10</h5>
          <h5 style="text-align: center; color: crimson">от 103 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_12'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_12.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 12</h5>
          <h5 style="text-align: center; color: crimson">от 103 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_13'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_13.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 13</h5>
          <h5 style="text-align: center; color: crimson">от 103 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_14'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_14.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 14</h5>
          <h5 style="text-align: center; color: crimson">от 103 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_15'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_15.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 15</h5>
          <h5 style="text-align: center; color: crimson">от 118 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_17'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_17.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 17</h5>
          <h5 style="text-align: center; color: crimson">от 103 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_19'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_19.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 19</h5>
          <h5 style="text-align: center; color: crimson">от 119 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_22'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_22.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 22</h5>
          <h5 style="text-align: center; color: crimson">от 103 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_25'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_25.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 25</h5>
          <h5 style="text-align: center; color: crimson">от 159 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_29'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_29.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 29</h5>
          <h5 style="text-align: center; color: crimson">от 159 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_31'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_31.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 31</h5>
          <h5 style="text-align: center; color: crimson">от 103 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_33'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_33.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 33</h5>
          <h5 style="text-align: center; color: crimson">от 103 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_34'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_34.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 34</h5>
          <h5 style="text-align: center; color: crimson">от 103 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_36'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL-36.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 36</h5>
          <h5 style="text-align: center; color: crimson">от 103 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_38'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_38.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 38</h5>
          <h5 style="text-align: center; color: crimson">от 119 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_39'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_39.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 39</h5>
          <h5 style="text-align: center; color: crimson">от 159 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_49'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_49.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт 49</h5>
          <h5 style="text-align: center; color: crimson">от 103 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'standart_tripoly2'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/MODEL_Tifani2.jpg" alt="">
          <h5 style="text-align: center">Серия Стандарт Триполи 2</h5>
          <h5 style="text-align: center; color: crimson">от 119 руб</h5>
        </div>
      </router-link>
    </div>
    <BackToSeries style="margin-top: 3rem"/>
  </div>
</template>

<script>
import doorseries from "@/data/doorseries.json";
import BackToSeries from "@/components/door-inside/BackToSeries";

export default {
  name: "DoorSiriesStandart",
  components: {BackToSeries},
  data() {
    return {
      doorSeries: doorseries,

    };
  },
  computed:{

  }

}
</script>

<style scoped>
.door-inside-series{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.door-series-container{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.door-series{
  max-width: 200px;
  max-height: 450px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-bottom: 0;
}
h5 {
  padding-bottom: 0;
  margin-bottom: 0;
}

.door-series-img{
  width: 220px;
  height: auto;
  margin: 1rem;
}
a.door-inside-series-link{
  max-height: 450px;
  margin: 0;
  padding: 0;

}
.door-inside-series-link{
  cursor: pointer;
  text-decoration: none;
  color: #198754;
  padding: 0;
  margin: 0;
}
.door-inside-series-title{
  margin: 1rem;
  text-align: center;
  font-weight: bolder;
  color: crimson;
}
.door-inside-series-subtitle{
  margin: 1rem;
  text-align: center;
}

</style>