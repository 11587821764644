<template>
  <div class="door-inside-series">
    <BackToSeries style="padding-top: 2rem"/>
    <h2 class="door-inside-series-title">Серия Армани</h2>
    <h4 class="door-inside-series-subtitle">Серия Армани - современный интерьер, за разумные деньги</h4>
    <div class="container door-series-container">

      <router-link class="door-inside-series-link"  :to="{name:'armany_00'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_00.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 00</h5>
          <h5 style="text-align: center; color: crimson">от 147 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_01'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_01.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 01</h5>
          <h5 style="text-align: center; color: crimson">от 147 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_02'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_02.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 02</h5>
          <h5 style="text-align: center; color: crimson">от 169 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_03'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_03.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 03</h5>
          <h5 style="text-align: center; color: crimson">от 147 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_04'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_04.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 04</h5>
          <h5 style="text-align: center; color: crimson">от 169 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_05'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_05.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 05</h5>
          <h5 style="text-align: center; color: crimson">от 187 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_06'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_06.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 06</h5>
          <h5 style="text-align: center; color: crimson">от 169 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_07'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_07.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 07</h5>
          <h5 style="text-align: center; color: crimson">от 169 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_09'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_09.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 09</h5>
          <h5 style="text-align: center; color: crimson">от 187 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_10'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_10.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 10</h5>
          <h5 style="text-align: center; color: crimson">от 187 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_11'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_11.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 11</h5>
          <h5 style="text-align: center; color: crimson">от 187 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_12'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_12.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 12</h5>
          <h5 style="text-align: center; color: crimson">от 169 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_13'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_13.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 13</h5>
          <h5 style="text-align: center; color: crimson">от 169 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_14'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_14.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 14</h5>
          <h5 style="text-align: center; color: crimson">от 169 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_15'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_15.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 15</h5>
          <h5 style="text-align: center; color: crimson">от 169 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_16'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_16.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 16</h5>
          <h5 style="text-align: center; color: crimson">от 169 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_17'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_17.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 17</h5>
          <h5 style="text-align: center; color: crimson">от 169 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_18'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_18.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 18</h5>
          <h5 style="text-align: center; color: crimson">от 187 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_19'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_19.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 19</h5>
          <h5 style="text-align: center; color: crimson">от 169 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_20'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_20.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 20</h5>
          <h5 style="text-align: center; color: crimson">от 169 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_21'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_21.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 21</h5>
          <h5 style="text-align: center; color: crimson">от 187 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_22'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/Amati_22.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 22</h5>
          <h5 style="text-align: center; color: crimson">от 187 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_25'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/armany_25.jpg" alt="">
          <h5 style="text-align: center">Серия Армани 25</h5>
          <h5 style="text-align: center; color: crimson">от 169 руб</h5>
        </div>
      </router-link>
      <router-link class="door-inside-series-link"  :to="{name:'armany_m1'}">
        <div class="door-series">
          <img class="door-series-img" src="../../assets/door-inside/door-series/armany_m1.jpg" alt="">
          <h5 style="text-align: center">Серия Армани M1</h5>
          <h5 style="text-align: center; color: crimson">от 145 руб</h5>
        </div>
      </router-link>
    </div>
    <BackToSeries/>
  </div>
</template>

<script>
import doorseries from "@/data/doorseries.json";
import BackToSeries from "@/components/door-inside/BackToSeries";

export default {
  name: "DoorSeriesArmany",
  components: {BackToSeries},
  data() {
    return {
      doorSeries: doorseries,

    };
  },
  computed:{

  }

}
</script>

<style scoped>
.door-inside-series{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.door-series-container{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.door-series{
  max-width: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  margin: 3rem;
}
.door-series-img{
  width: 220px;
  height: auto;
  margin: 1rem;
}
.door-inside-series-link{
  cursor: pointer;
  text-decoration: none;
  color: #198754;
}
.door-inside-series-title{
  margin: 1rem;
  text-align: center;
  font-weight: bolder;
  color: crimson;
}
.door-inside-series-subtitle{
  margin: 1rem;
  text-align: center;
}
</style>