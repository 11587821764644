<template>
  <div class="entrance-door-garda">
    <div class="main-title">
      <h2 class="arcks-title"> Входные двери Сталлер Комфорт</h2>
    </div>
    <EntranceDoorBack/>
    <h5 style="text-align: center; background-color: #0b6efd; margin: 1rem; padding: .5rem; color: white;">Для некоторых
      дверей цвет внутренней отделки имеет несколько вариантов. Уточняйте возможные варианты в интересующих вас моделях.
      Или проконсультируйтесь у нашего специалиста.</h5>

    <div class=" entrance-door-container container">
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-comfort-keln'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/keln_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ КЕЛЬН">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/keln_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ КЕЛЬН">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 963 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР КОМФОРТ КЕЛЬН</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-comfort-dresden'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/dresden_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ ДРЕЗДЕН">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/dresden_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ ДРЕЗДЕН">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 970 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР КОМФОРТ ДРЕЗДЕН</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-comfort-gamburg'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/gamburg_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ ГАМБУРГ">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/gamburg_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ ГАМБУРГ">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 970 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР КОМФОРТ ГАМБУРГ</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-comfort-nois'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/nois_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ НОЙС">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/nois_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ НОЙС">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 987 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР КОМФОРТ НОЙС</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-comfort-bavaria'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/bavaria_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ БАВАРИЯ">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/bavaria_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ БАВАРИЯ">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1004 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР КОМФОРТ БАВАРИЯ</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-comfort-alen'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/alen_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ АЛЕН">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/alen_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ АЛЕН">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1011 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР КОМФОРТ АЛЕН</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-comfort-baden'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/baden_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ БАДЕН">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/baden_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ БАДЕН">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1018 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР КОМФОРТ БАДЕН</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-comfort-stutgart'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/stutgart_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ ШТУТГАРТ">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/stutgart_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ ШТУТГАРТ">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1024 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР КОМФОРТ ШТУТГАРТ</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-comfort-munhen'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/munhen_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ МЮНХЕН 1">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/munhen_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ МЮНХЕН 1">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1031 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР КОМФОРТ МЮНХЕН 1</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-comfort-hanover'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/hanover_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ ГАННОВЕР">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/hanover_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ ГАННОВЕР">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1032 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР КОМФОРТ ГАННОВЕР</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-comfort-berlin'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/berlin_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ БЕРЛИН">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/berlin_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ БЕРЛИН">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1032 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР КОМФОРТ БЕРЛИН</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-comfort-marburg'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/marburg_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ МАРБУРГ">
            <img class="door-series-img" src="../../assets/entrance-door/staller-comfort/marburg_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КОМФОРТ МАРБУРГ">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1045 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР КОМФОРТ МАРБУРГ</h6>
          </div>
        </router-link>
      </div>
    </div>
    <ContactContainerMobile/>
  </div>
</template>

<script>
import EntranceDoorBack from "@/components/entrance-door/EntranceDoorBack";
import ContactContainerMobile from "@/components/mobile/mobileMenu/ContactContainerMobile";
export default {
  name: "EntranceDoorStallerComfort",
  components: {EntranceDoorBack, ContactContainerMobile}
}
</script>

<style scoped>
.entrance-door-garda{
  width: 100%;
  display: flex;

  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-sizing: border-box;
}
.main-title{
  width: 100%;
  display: flex;
  justify-content: center;
}
.door-title{
  color: crimson;
  padding-bottom: 2rem;
}
.entrance-door-container{
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.entrance-door-series{
  width: 260px;
  height: 450px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
}
.entrance-door-series-img-container{
  width: 100%;
  display: flex;
  justify-content: center;
}
.door-series-img{
  width: 120px;
  height: auto;
}
.entrance-door-series-content-container{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
a{
  text-decoration: none;
  color: black;
}
</style>