<template>
<div class="container-fluid benefit-wrapper">
  <h4 style="text-align: center; background-color: darkgoldenrod; color: aliceblue">Наши преймущества, таких условий больше вам не предложит никто !!!</h4>
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-12">
      <div class="card border-danger mb-3">
        <div class="row g-0">
          <div class="col-md-4">
            <img  style="padding: 1rem"
                src="../../assets/logos/Ekspert.jpg"
                alt="Замер выполняет монтажник"
                class="img-fluid rounded-start"
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title text-bg-success">Замер выполняет монтажник</h5>
              <p class="card-text">
               Именно! Замеряет размеры проемов тот, кто будет устанавливать изделие.
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card border-danger mb-3" >
        <div class="row g-0">
          <div class="col-md-4">
            <img style="padding: 1rem"
                src="../../assets/logos/window_vector_154959.jpg"
                alt="Trendy Pants and Shoes"
                class="img-fluid rounded-start"
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title text-bg-primary">На любой вкус</h5>
              <p class="card-text">
                Размер, форма и комплектация необходимых вам окон ограничена только вашей фантазией и бюджетом
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-12">
      <div class="card border-danger mb-3">
        <div class="row g-0">
          <div class="col-md-4">
            <img  style="padding: 1rem; "
                  src="../../assets/logos/ocr.jpg"
                  alt="Замер выполняет монтажник"
                  class="img-fluid rounded-start"
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title text-bg-info">Срок эксплуатации до 70 лет</h5>
              <p class="card-text">
                Хорошие, правильно установленные окна, могут прослужить невероятно долго до 70 лет.

              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card border-danger mb-3">
        <div class="row g-0">
          <div class="col-md-4">
            <img style="padding: 1rem"
                 src="../../assets/logos/i.jpg"
                 alt="Trendy Pants and Shoes"
                 class="img-fluid rounded-start"
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title text-bg-danger">Пожизненная гарантия на стеклопакеты</h5>
              <p class="card-text">
                Да, мы настолько уверены в нашей продукции, что предоставляем на стеклопакеты пожизненную гарантию.
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "BenefitsOf"
}
</script>

<style scoped>
.benefit-wrapper{
  padding-top: 3rem;
}
</style>