<template>
<div class="call-back-me">
  <img src="../assets/logos/rectangle.jpg" class="img-calback img-fluid">
</div>
</template>

<script>
export default {
  name: "CallbackMe"
}
</script>

<style scoped>
.call-back-me{
  margin-bottom: 2rem;
}
</style>