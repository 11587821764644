<template>
  <div class="armany">
    <BackToSeries/>
    <h1 class="armany-title">Армани M1</h1>
    <div class="container">
      <h2 class="armany-subtitle">От 146 руб за полотно и от 263 за комплект с матовым стеклом</h2>
      <h2 class="armany-subtitle">От 163 руб за полотно и от 279 за комплект с черным стеклом</h2>
      <h4 class="armany-subtitle-desc">Окончательная стоимость может зависить от типа фурнитуры, необходимости использовать дополнительные компоненты
        и других факторов, - проконсультируйтесь с нашим специалистом. Это - бесплатно!</h4>
      <ColorAttension/>
    </div>
    <div class="container door-series-container">
      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1графит.jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Шале графит</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span> Матовое</h6>
      </div>
      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1графит(ч).jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Шале графит</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span> Черное</h6>
      </div>

      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1_СкандиКлассик.jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Сканди класик</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span> Матовое</h6>
      </div>

      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1корица.jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Шале корица</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span> Матовое</h6>
      </div>
      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1корица(ч).jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Шале корица</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span> Черное</h6>
      </div>
      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1натур.jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Шале натуральный</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span>Матовое</h6>
      </div>
      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1натур(ч).jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Шале натуральный</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span>Черное</h6>
      </div>

      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1эшвайт.jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Эшвайт</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span> Матовое</h6>
      </div>
      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1эшвайт(ч).jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Эшвайт</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span> Черное</h6>
      </div>


      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1беленыйдуб.jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Беленый дуб</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span> Матовое</h6>
      </div>
      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1беленыйдуб(ч).jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Беленый дуб</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span> Черное</h6>
      </div>
      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1дубдымчатый.jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Дуб дымчатый</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span> Матовое</h6>
      </div>

      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1дубдымчатый(ч).jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Дуб дымчатый</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span> Черное</h6>
      </div>

      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1венге.jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Венге</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span> Матовое</h6>
      </div>
      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1венге(ч).jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Венге</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span> Черное</h6>
      </div>
      <div class="door-series">
        <img class="door-series-img" src="../../../assets/door-inside/door-series/armany_m1/_М1_Бьянко.jpg" alt="">
        <h6 class="door-series-desc" ><span>Цвет:</span> Бьянко</h6>
        <h6 class="door-series-desc" ><span>Стекло:</span> Матовое</h6>
      </div>
    </div>
    <BackToSeries/>
    <ContactContainerMobile/>
  </div>
</template>

<script>
import BackToSeries from "@/components/door-inside/BackToSeries";
import ContactContainerMobile from "@/components/mobile/mobileMenu/ContactContainerMobile";
import ColorAttension from "@/components/door-inside/ColorAttension";

export default {
  name: "DoorArmanyM1",
  components: {ColorAttension, ContactContainerMobile, BackToSeries}
}
</script>

<style scoped>
@import "@/assets/styles/door_armany.css";
</style>