<template>
<div class="knob-wrapper">
  <h1 class="knob-title">Дверные ручки <span class="knob-subtitle">  (Для межкомнатных дверей)</span></h1>
  <div class="container knob-content">
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-74.jpg" alt="">
      <h6 class="knob-id">ID 35749</h6>
      <p class="knob-desc" >LOGICA TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 20,9 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-75.jpg" alt="">
      <h6 class="knob-id">ID 36325</h6>
      <p class="knob-desc" >LOGICA TL SG/GP-4 матовое золото/золото</p>
      <h5 class="knob-price" > 20,9 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-76.jpg" alt="">
      <h6 class="knob-id">ID 36327</h6>
      <p class="knob-desc" >LOGICA TL ABG-6 зеленая бронза</p>
      <h5 class="knob-price" > 20,9 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-77.jpg" alt="">
      <h6 class="knob-id">ID 37047</h6>
      <p class="knob-desc" >REX TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 23,2 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-78.jpg" alt="">
      <h6 class="knob-id">ID 38321</h6>
      <p class="knob-desc" > REX TL ABG-6 зеленая бронза 105 мм</p>
      <h5 class="knob-price" > 24,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/38323_1.jpg" alt="">
      <h6 class="knob-id">ID 38323</h6>
      <p class="knob-desc" >REX TL SG/GP-4 матовое золото/золото 105 мм</p>
      <h5 class="knob-price" > 24,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/37669_1.jpg" alt="">
      <h6 class="knob-id">ID 37669</h6>
      <p class="knob-desc" > REX TL SN/CP-3 матовый никель/хром 105 мм</p>
      <h5 class="knob-price" > 24,5 р. </h5>
    </div>
    <div class="knob">
      <div class="img-container">
          <img class="knob-img" loading="lazy" src="../../assets/knob/КаприSNCP.jpg" alt="">
      </div>
      <div class="content-container">
          <h6 class="knob-id">Капри-SN/CP</h6>
          <p class="knob-desc" >Капри-SN/CP Матовый хром/хром</p>
          <h5 class="knob-price" > 25,70 р. </h5>
    </div>
    </div>
    <div class="knob">
      <div class="img-container">
          <img class="knob-img" loading="lazy" src="../../assets/knob/ИмперияSNCP.jpg" alt="">
      </div>
      <div class="content-container">
          <h6 class="knob-id">Империя-SN/CP</h6>
          <p class="knob-desc" >Империя-SN/CP Матовый хром/хром</p>
          <h5 class="knob-price" > 26,59 р. </h5>
      </div>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-100.jpg" alt="">
      <h6 class="knob-id">ID 39587</h6>
      <p class="knob-desc" >PALMIRA MT OS-9 античное серебро</p>
      <h5 class="knob-price" > 28,3 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-125.jpg" alt="">
      <h6 class="knob-id">ID 38389</h6>
      <p class="knob-desc" > CRONA TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 29,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-126.jpg" alt="">
      <h6 class="knob-id">ID 38391</h6>
      <p class="knob-desc" >CRONA TL ABG-6 зеленая бронза</p>
      <h5 class="knob-price" > 29,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-127.jpg" alt="">
      <h6 class="knob-id">ID 38393</h6>
      <p class="knob-desc" >CRONA TL SG/GP-4 мат. золото/золото</p>
      <h5 class="knob-price" > 29,0 р. </h5>
    </div>
    <div class="knob">
      <div class="img-container">
       <img class="knob-img" loading="lazy" src="../../assets/knob/РикоSNCP.jpg" alt="">
      </div>
      <div class="content-container">
          <h6 class="knob-id">Рико-SN/CP</h6>
          <p class="knob-desc" >Рико-SN/CP Матовый хром/хром</p>
          <h5 class="knob-price" > 29,65 р. </h5>
      </div>
    </div>
    <div class="knob">
      <div class="img-container">
        <img class="knob-img" loading="lazy" src="../../assets/knob/РикоBLCP.jpg" alt="">
      </div>
      <div class="content-container">
          <h6 class="knob-id">Рико-BL/CP</h6>
          <p class="knob-desc" >Рико-SN/CP Хром/черный</p>
          <h5 class="knob-price" > 29,65 р. </h5>
      </div>
    </div>
    <div class="knob">
      <div class="img-container">
       <img class="knob-img" loading="lazy" src="../../assets/knob/РикоWHCP.jpg" alt="">
      </div>
      <div class="content-container">
          <h6 class="knob-id">Рико-WH/CP</h6>
          <p class="knob-desc" >Рико-SN/CP Хром/белый</p>
          <h5 class="knob-price" > 29,65 р. </h5>
      </div>
    </div>
    <div class="knob">
      <div class="img-container">
           <img class="knob-img" loading="lazy" src="../../assets/knob/ТерамоSN.jpg" alt="">
      </div>
      <div class="content-container">
          <h6 class="knob-id">Терамо-SN</h6>
          <p class="knob-desc" >Комо-SN Сатинированный никель</p>
          <h5 class="knob-price" > 31,73 р. </h5>
      </div>
    </div>
    <div class="knob">
      <div class="img-container">
          <img class="knob-img" loading="lazy" src="../../assets/knob/КомоSN.jpg" alt="">
      </div>
      <div class="content-container">
          <h6 class="knob-id">Комо-SN</h6>
          <p class="knob-desc" >Комо-SN Сатинированный никель</p>
          <h5 class="knob-price" > 31,73 р. </h5>
      </div>
    </div>
    <div class="knob">
      <div class="img-container">
        <img class="knob-img" loading="lazy" src="../../assets/knob/ГрандSN.jpg" alt="">
      </div>
      <div class="content-container">
        <h6 class="knob-id">Гранд-SN</h6>
        <p class="knob-desc" >Гранд-SN Сатинированный никель</p>
        <h5 class="knob-price" > 32,63 р. </h5>
      </div>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-34.jpg" alt="">
      <h6 class="knob-id">ID 33023</h6>
      <p class="knob-desc" >ALFA TL ABG-6 зеленая бронза</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-36.jpg" alt="">
      <h6 class="knob-id">ID 33027</h6>
      <p class="knob-desc" >ALFA TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-37.jpg" alt="">
      <h6 class="knob-id">ID 33029</h6>
      <p class="knob-desc" >ARDEA TL ABG-6 зеленая бронза</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-38.jpg" alt="">
      <h6 class="knob-id">ID 33031</h6>
      <p class="knob-desc" >ARDEA TL SG/GP-4 матовое золото/золото</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-39.jpg" alt="">
      <h6 class="knob-id">ID 33033</h6>
      <p class="knob-desc" >ARDEA TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-42.jpg" alt="">
      <h6 class="knob-id">ID 33045</h6>
      <p class="knob-desc" >BASIS TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-46.jpg" alt="">
      <h6 class="knob-id">ID 33071</h6>
      <p class="knob-desc" >BOSTON TL CF-17 кофе</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-47.jpg" alt="">
      <h6 class="knob-id">ID 33073</h6>
      <p class="knob-desc" >BOSTON TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-51.jpg" alt="">
      <h6 class="knob-id">ID 33183</h6>
      <p class="knob-desc" >RUMBA TL ABG-6 зеленая бронза</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-52.jpg" alt="">
      <h6 class="knob-id">ID 33185</h6>
      <p class="knob-desc" >RUMBA TL CFB-18 кофе глянец</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-53.jpg" alt="">
      <h6 class="knob-id">ID 33187</h6>
      <p class="knob-desc" >RUMBA TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-54.jpg" alt="">
      <h6 class="knob-id">ID 33189</h6>
      <p class="knob-desc" >SALSA TL ABG-6 зеленая бронза</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-55.jpg" alt="">
      <h6 class="knob-id">ID 33191</h6>
      <p class="knob-desc" >SALSA TL CFB-18 кофе глянец</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-56.jpg" alt="">
      <h6 class="knob-id">ID 33193</h6>
      <p class="knob-desc" >SALSA TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-60.jpg" alt="">
      <h6 class="knob-id">ID 33485</h6>
      <p class="knob-desc" >ARFA TL ABG-6 зеленая бронза</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-61.jpg" alt="">
      <h6 class="knob-id">ID 33487</h6>
      <p class="knob-desc" >ARFA TL CF-17 кофе</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-62.jpg" alt="">
      <h6 class="knob-id">ID 33489</h6>
      <p class="knob-desc" >ARFA TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-65.jpg" alt="">
      <h6 class="knob-id">ID 34765</h6>
      <p class="knob-desc" >FLOR TL CF-17 кофе</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-71.jpg" alt="">
      <h6 class="knob-id">ID 34785</h6>
      <p class="knob-desc" >ONDA TL ABG-6 зеленая бронза</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-72.jpg" alt="">
      <h6 class="knob-id">ID 34787</h6>
      <p class="knob-desc" >ONDA TL CF-17 кофе</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-73.jpg" alt="">
      <h6 class="knob-id">ID 34789</h6>
      <p class="knob-desc" >ONDA TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-123.jpg" alt="">
      <h6 class="knob-id">ID 38381</h6>
      <p class="knob-desc" >CITY TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-129.jpg" alt="">
      <h6 class="knob-id">ID 40149</h6>
      <p class="knob-desc" >BLADE TL SSC-16 сатинированный хром</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-130.jpg" alt="">
      <h6 class="knob-id">ID 40155</h6>
      <p class="knob-desc" >BLADE TL GR-23 графит</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-131.jpg" alt="">
      <h6 class="knob-id">ID 40157</h6>
      <p class="knob-desc" >BLADE TL BL-24 черный</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-132.jpg" alt="">
      <h6 class="knob-id">ID 40159</h6>
      <p class="knob-desc" >BLADE TL WH-19 белый</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/35321_01.jpg" alt="">
      <h6 class="knob-id">ID 35321</h6>
      <p class="knob-desc" >RUMBA TL CF-17 кофе</p>
      <h5 class="knob-price" > 33,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-35.jpg" alt="">
      <h6 class="knob-id">ID 33025</h6>
      <p class="knob-desc" >ALFA TL CFB-18 кофе глянец</p>
      <h5 class="knob-price" > 36,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-40.jpg" alt="">
      <h6 class="knob-id">ID 33041</h6>
      <p class="knob-desc" >BASIS TL ABG-6 зеленая бронза</p>
      <h5 class="knob-price" > 36,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-41.jpg" alt="">
      <h6 class="knob-id">ID 33043</h6>
      <p class="knob-desc" >BASIS TL CF-17 кофе</p>
      <h5 class="knob-price" > 36,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-43.jpg" alt="">
      <h6 class="knob-id">ID 33065</h6>
      <p class="knob-desc" >BOLERO TL ABG-6 зеленая бронза</p>
      <h5 class="knob-price" > 36,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-45.jpg" alt="">
      <h6 class="knob-id">ID 33069</h6>
      <p class="knob-desc" >BOLERO TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 36,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-48.jpg" alt="">
      <h6 class="knob-id">ID 33169</h6>
      <p class="knob-desc" >PELICAN TL CF-17 кофе</p>
      <h5 class="knob-price" > 36,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-49.jpg" alt="">
      <h6 class="knob-id">ID 33171</h6>
      <p class="knob-desc" >PELICAN TL SG/GP-4 матовое золото/золото</p>
      <h5 class="knob-price" > 36,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-50.jpg" alt="">
      <h6 class="knob-id">ID 33171</h6>
      <p class="knob-desc" >PELICAN TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 36,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-57.jpg" alt="">
      <h6 class="knob-id">ID 33195</h6>
      <p class="knob-desc" >SIMFONIA TL ABG-6 зеленая бронза</p>
      <h5 class="knob-price" > 36,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-58.jpg" alt="">
      <h6 class="knob-id">ID 33197</h6>
      <p class="knob-desc" >SIMFONIA TL SG/GP-4 матовое золото/золото</p>
      <h5 class="knob-price" > 36,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-59.jpg" alt="">
      <h6 class="knob-id">ID 33199</h6>
      <p class="knob-desc" >SIMFONIA TL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 36,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-2.jpg" alt="">
      <h6 class="knob-id">ID 33063</h6>
      <p class="knob-desc" >BLADE QL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-3.jpg" alt="">
      <h6 class="knob-id">ID 33081</h6>
      <p class="knob-desc" >CITY QL ABG-6 зеленая бронза</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-4.jpg" alt="">
      <h6 class="knob-id">ID 33083</h6>
      <p class="knob-desc" >CITY QL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-6.jpg" alt="">
      <h6 class="knob-id">ID 33163</h6>
      <p class="knob-desc" >NAVY QL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-7.jpg" alt="">
      <h6 class="knob-id">ID 33201</h6>
      <p class="knob-desc" >TECH QL CF-17 кофе</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-8.jpg" alt="">
      <h6 class="knob-id">ID 33203</h6>
      <p class="knob-desc" >TECH QL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-9.jpg" alt="">
      <h6 class="knob-id">ID 37001</h6>
      <p class="knob-desc" >TECH QL GR/CP-23 графит/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-10.jpg" alt="">
      <h6 class="knob-id">ID 37003</h6>
      <p class="knob-desc" >NAVY QL GR/CP-23 графит/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-11.jpg" alt="">
      <h6 class="knob-id">ID 37005</h6>
      <p class="knob-desc" >CITY QL GR/CP-23 графит/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-12.jpg" alt="">
      <h6 class="knob-id">ID 37007</h6>
      <p class="knob-desc" >BLADE QL GR/CP-23 графит/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-13.jpg" alt="">
      <h6 class="knob-id">ID 34779</h6>
      <p class="knob-desc" >MARS QR GR/CP-23 графит/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-14.jpg" alt="">
      <h6 class="knob-id">ID 34781</h6>
      <p class="knob-desc" >MARS QR SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-15.jpg" alt="">
      <h6 class="knob-id">ID 34783</h6>
      <p class="knob-desc" >MARS QR SN/WH-19 матовый никель/белый</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-16.jpg" alt="">
      <h6 class="knob-id">ID 34791</h6>
      <p class="knob-desc" >ORION QR GR/CP-23 графит/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-17.jpg" alt="">
      <h6 class="knob-id">ID 34793</h6>
      <p class="knob-desc" >ORION QR SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-18.jpg" alt="">
      <h6 class="knob-id">ID 34795</h6>
      <p class="knob-desc" >SATURN QR GR/CP-23 графит/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-19.jpg" alt="">
      <h6 class="knob-id">ID 34797</h6>
      <p class="knob-desc" >SATURN QR SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-20.jpg" alt="">
      <h6 class="knob-id">ID 34799</h6>
      <p class="knob-desc" >VESTA QR GR/CP-23 графит/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-21.jpg" alt="">
      <h6 class="knob-id">ID 34801</h6>
      <p class="knob-desc" >VESTA QR SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-22.jpg" alt="">
      <h6 class="knob-id">ID 37009</h6>
      <p class="knob-desc" >GALAXY QR GR/CP-23 графит/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-23.jpg" alt="">
      <h6 class="knob-id">ID 37011</h6>
      <p class="knob-desc" >GALAXY QR SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-24.jpg" alt="">
      <h6 class="knob-id">ID 37013</h6>
      <p class="knob-desc" >PLUTON QR GR/CP-23 графит/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-25.jpg" alt="">
      <h6 class="knob-id">ID 37015</h6>
      <p class="knob-desc" >PLUTON QR SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-81.jpg" alt="">
      <h6 class="knob-id">ID 37521</h6>
      <p class="knob-desc" >NOVA QR GR/CP-23 графит/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-86.jpg" alt="">
      <h6 class="knob-id">ID 38723</h6>
      <p class="knob-desc" >STYLE QL SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-87.jpg" alt="">
      <h6 class="knob-id">ID 38725</h6>
      <p class="knob-desc" >STYLE QL GR/CP-23 графит/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41747_01.jpg" alt="">
      <h6 class="knob-id">ID 41747</h6>
      <p class="knob-desc" >VECTOR QL BL-24 чёрный</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41743_01.jpg" alt="">
      <h6 class="knob-id">ID 41743</h6>
      <p class="knob-desc" >STYLE QL BL-24 черный</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41745_01.jpg" alt="">
      <h6 class="knob-id">ID 41745</h6>
      <p class="knob-desc" >BLADE QL BL-24 черный</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/40151_01.jpg" alt="">
      <h6 class="knob-id">ID 40151</h6>
      <p class="knob-desc" >METEOR QR GR/CP-23 графит/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/37519_01.jpg" alt="">
      <h6 class="knob-id">ID 37519</h6>
      <p class="knob-desc" >NOVA QR SN/WH-19 мат.никель/белый</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/36013_01.jpg" alt="">
      <h6 class="knob-id">ID 36013</h6>
      <p class="knob-desc" >NOVA QR SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/40153_01.jpg" alt="">
      <h6 class="knob-id">ID 40153</h6>
      <p class="knob-desc" >METEOR QR SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-402.jpg" alt="">
      <h6 class="knob-id">ID 33061</h6>
      <p class="knob-desc" >BLADE QL ABG-6 зеленая бронза</p>
      <h5 class="knob-price" > 37,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-399.jpg" alt="">
      <h6 class="knob-id">ID 33205</h6>
      <p class="knob-desc" >VENTO ML ABG-6 зеленая бронза</p>
      <h5 class="knob-price" > 38,4 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-401.jpg" alt="">
      <h6 class="knob-id">ID 33209</h6>
      <p class="knob-desc" >VENTO ML SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 38,4 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-90.jpg" alt="">
      <h6 class="knob-id">ID 39567</h6>
      <p class="knob-desc" >MADRID MT OB-13 античная бронза</p>
      <h5 class="knob-price" > 38,4 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-92.jpg" alt="">
      <h6 class="knob-id">ID 39571</h6>
      <p class="knob-desc" >MADRID MT OS-9 античное серебро</p>
      <h5 class="knob-price" > 39,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-95.jpg" alt="">
      <h6 class="knob-id">ID 39577</h6>
      <p class="knob-desc" >BELLAGIO MT OB-13 античная бронза</p>
      <h5 class="knob-price" > 39,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-97.jpg" alt="">
      <h6 class="knob-id">ID 39581</h6>
      <p class="knob-desc" >BELLAGIO MT OS-9 античное серебро</p>
      <h5 class="knob-price" > 39,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-98.jpg" alt="">
      <h6 class="knob-id">ID 39583</h6>
      <p class="knob-desc" >PALMIRA MT OB-13 античная бронза</p>
      <h5 class="knob-price" > 39,5 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/01-5.jpg" alt="">
      <h6 class="knob-id">ID 33161</h6>
      <p class="knob-desc" >NAVY QL CF-17 кофе</p>
      <h5 class="knob-price" > 41,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/46389_01.jpg" alt="">
      <h6 class="knob-id">ID 46389</h6>
      <p class="knob-desc" >K.QSL52.TITAN СP/WH-19 хром/белый</p>
      <h5 class="knob-price" > 47,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/46391_01.jpg" alt="">
      <h6 class="knob-id">ID 46391</h6>
      <p class="knob-desc" >K.QSL52.TITAN BL-24 черный</p>
      <h5 class="knob-price" > 47,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/46393_01.jpg" alt="">
      <h6 class="knob-id">ID 46393</h6>
      <p class="knob-desc" >K.QSL52.TITAN SN/BL-3 мат.никель/черный</p>
      <h5 class="knob-price" > 47,0 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41077_01.jpg" alt="">
      <h6 class="knob-id">ID 41077</h6>
      <p class="knob-desc" >AXIS ZR BL-24 чёрный</p>
      <h5 class="knob-price" > 52,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41099_01.jpg" alt="">
      <h6 class="knob-id">ID 41099</h6>
      <p class="knob-desc" >AXIS ZR SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 52,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41079_01.jpg" alt="">
      <h6 class="knob-id">ID 41079</h6>
      <p class="knob-desc" >SUPREME ZR ABG-6 бронза</p>
      <h5 class="knob-price" > 52,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41105_01.jpg" alt="">
      <h6 class="knob-id">ID 41105</h6>
      <p class="knob-desc" >SUPREME ZR SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 52,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41097_01.jpg" alt="">
      <h6 class="knob-id">ID 41097</h6>
      <p class="knob-desc" >COMETA ZR SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 52,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41125_01.jpg" alt="">
      <h6 class="knob-id">ID 41125</h6>
      <p class="knob-desc" >UNI ZR SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 52,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41115_01.jpg" alt="">
      <h6 class="knob-id">ID 41115</h6>
      <p class="knob-desc" >SERENITY ZR OS-9 античное серебро</p>
      <h5 class="knob-price" > 52,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41119_01.jpg" alt="">
      <h6 class="knob-id">ID 41119</h6>
      <p class="knob-desc" >SERENITY ZR OB-13 античная бронза</p>
      <h5 class="knob-price" > 52,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41073_01.jpg" alt="">
      <h6 class="knob-id">ID 41073</h6>
      <p class="knob-desc" >LORENA ZR OB-13 античная бронза</p>
      <h5 class="knob-price" > 55,1 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41095_01.jpg" alt="">
      <h6 class="knob-id">ID 41095</h6>
      <p class="knob-desc" >LORENA ZR OS-9 античное серебро</p>
      <h5 class="knob-price" > 55,1 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41111_01.jpg" alt="">
      <h6 class="knob-id">ID 41111</h6>
      <p class="knob-desc" >LORENA ZR BL-24 чёрный</p>
      <h5 class="knob-price" > 55,1 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41127_01.jpg" alt="">
      <h6 class="knob-id">ID 41127</h6>
      <p class="knob-desc" >FENIX ZR ABG-6 бронза</p>
      <h5 class="knob-price" > 55,1 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41035_01.jpg" alt="">
      <h6 class="knob-id">ID 41035</h6>
      <p class="knob-desc" >DROID ZQ SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 59,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41045_01.jpg" alt="">
      <h6 class="knob-id">ID 41045</h6>
      <p class="knob-desc" >DROID ZQ BL-24 чёрный</p>
      <h5 class="knob-price" > 59,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41043_01.jpg" alt="">
      <h6 class="knob-id">ID 41043</h6>
      <p class="knob-desc" >FUTURA ZQ GR/BN-23 графит/чёрный никель</p>
      <h5 class="knob-price" > 59,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41039_01.jpg" alt="">
      <h6 class="knob-id">ID 41039</h6>
      <p class="knob-desc" >FUTURA ZQ SN/WH-19 матовый никель/белый</p>
      <h5 class="knob-price" > 59,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41033_01.jpg" alt="">
      <h6 class="knob-id">ID 41033</h6>
      <p class="knob-desc" >ROTO ZQ GR-23 графит</p>
      <h5 class="knob-price" > 59,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41037_01.jpg" alt="">
      <h6 class="knob-id">ID 41037</h6>
      <p class="knob-desc" >BIO ZQ SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 59,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41041_01.jpg" alt="">
      <h6 class="knob-id">ID 41041</h6>
      <p class="knob-desc" >INTEGRA ZQ BL-24 чёрный</p>
      <h5 class="knob-price" > 59,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41047_01.jpg" alt="">
      <h6 class="knob-id">ID 41047</h6>
      <p class="knob-desc" >INTEGRA ZQ SN/CP-3 матовый никель/хром</p>
      <h5 class="knob-price" > 59,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41049_01.jpg" alt="">
      <h6 class="knob-id">ID 41049</h6>
      <p class="knob-desc" >INTEGRA ZQ GR-23 графит</p>
      <h5 class="knob-price" > 59,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41051_01.jpg" alt="">
      <h6 class="knob-id">ID 41051</h6>
      <p class="knob-desc" >BIO ZQ GR-23 графит</p>
      <h5 class="knob-price" > 59,6 р. </h5>
    </div>
    <div class="knob">
      <img class="knob-img" loading="lazy" src="../../assets/knob/41055_01.jpg" alt="">
      <h6 class="knob-id">ID 41055</h6>
      <p class="knob-desc" >BIO ZQ GR-23 графит</p>
      <h5 class="knob-price" > 59,6 р. </h5>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "DoorKnobWrapper"
}
</script>

<style scoped>
.knob-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.knob-content{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.knob{
  max-width: 280px;
  height: 350px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin: 2rem;
}
.img-container{
  max-width: 200px;
  height: 163px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-container{

  width: 100%;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.knob-img{
  max-width: 200px;
  height: auto;
}
.knob-id{
  color: #0b6efd;
}
.knob-desc{
  width: 100%;
  text-align: center;
  padding-top: .5rem;
  padding-bottom: .5rem
}
.knob-price{
  width: 100%;
  text-align: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  color: crimson;
}
.knob-title{
  padding: 1rem;
  margin-top: 2rem;
  color: crimson;
}
.knob-subtitle{
  color: black;
  font-size: medium;
}
</style>