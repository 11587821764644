<template>
  <div class="the-first-line">
    У нас сезон подарков, - звони и узнай, что мы сегодня дарим.
  </div>
</template>

<script>
export default {
  name: "RedLinePromo"
}
</script>

<style scoped>
.the-first-line{
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  color: aliceblue;
  background-color: crimson;
  text-align: center;
}
</style>