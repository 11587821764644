<template>
  <div class="entrance-door-staller">
    <div class="main-title">
      <h2 class="arcks-title"> Входные двери Сталлер</h2>
    </div>
    <EntranceDoorBack/>
    <h5 style="text-align: center; background-color: #0b6efd; margin: 1rem; padding: .5rem; color: white;">Для некоторых
      дверей цвет внутренней отделки имеет несколько вариантов. Уточняйте возможные варианты в интересующих вас моделях.
      Или проконсультируйтесь у нашего специалиста.</h5>

    <div class=" entrance-door-container container">
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-rafinato'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/РАФФИНАТО_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР РАФФИНАТО">
            <img class="door-series-img" src="../../assets/entrance-door/staller/РАФФИНАТО_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР РАФФИНАТО">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1205 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР РАФФИНАТО</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-alba'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/alba_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР АЛЬБА">
            <img class="door-series-img" src="../../assets/entrance-door/staller/alba_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР АЛЬБА">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1224 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР АЛЬБА</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-vita'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/vita_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР ВИТА">
            <img class="door-series-img" src="../../assets/entrance-door/staller/vita_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР ВИТА">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1319 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР ВИТА</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-piano'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/piano_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР ПИАНО">
            <img class="door-series-img" src="../../assets/entrance-door/staller/piano_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР ПИАНО">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1319 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР ПИАНО</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-bruno-grafit'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/bruno_grafit_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР БРУНО Шале графит">
            <img class="door-series-img" src="../../assets/entrance-door/staller/bruno_grafit_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР БРУНО Шале графит">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1332 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР БРУНО Шале графит</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-rock'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/rock_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР СКАЛА">
            <img class="door-series-img" src="../../assets/entrance-door/staller/rock_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР СКАЛА">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1347 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР СКАЛА</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-tor'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/tor_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР ТОР">
            <img class="door-series-img" src="../../assets/entrance-door/staller/tor_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР ТОР">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1362 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР ТОР</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-milena'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/milena_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР МИЛЕНА">
            <img class="door-series-img" src="../../assets/entrance-door/staller/milena_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР МИЛЕНА">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1383 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР МИЛЕНА</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-toronto'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/toronto_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР ТОРОНТО">
            <img class="door-series-img" src="../../assets/entrance-door/staller/toronto_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР ТОРОНТО">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1397 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР ТОРОНТО</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-cvadro'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/cvadro_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КВАДРО ">
            <img class="door-series-img" src="../../assets/entrance-door/staller/cvadro_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР КВАДРО ">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1405 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР КВАДРО </h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-etna'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/etna_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР ЭТНА ">
            <img class="door-series-img" src="../../assets/entrance-door/staller/etna_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР ЭТНА ">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1440 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР ЭТНА </h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-moderno'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/moderno_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР МОДЕРНО ">
            <img class="door-series-img" src="../../assets/entrance-door/staller/moderno_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР МОДЕРНО ">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1440 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР МОДЕРНО </h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-natta'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/natta_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР НАТТА ">
            <img class="door-series-img" src="../../assets/entrance-door/staller/natta_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР НАТТА ">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1469 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР НАТТА </h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-diona'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/diona_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР ДИОНА ">
            <img class="door-series-img" src="../../assets/entrance-door/staller/diona_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР ДИОНА ">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1520 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР ДИОНА </h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-betolla'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/betolla_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР БЕТОЛЛА ">
            <img class="door-series-img" src="../../assets/entrance-door/staller/betolla_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР БЕТОЛЛА ">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1535 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР БЕТОЛЛА </h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-texas'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/texas_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР ТЕХАС ">
            <img class="door-series-img" src="../../assets/entrance-door/staller/texas_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР ТЕХАС ">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1570 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР ТЕХАС </h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-stilo2'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/stillo2_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР СТИЛО 2">
            <img class="door-series-img" src="../../assets/entrance-door/staller/stillo2_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР СТИЛО 2">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1621 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР СТИЛО 2</h6>
          </div>
        </router-link>
      </div>
      <div class="entrance-door-series">
        <router-link  :to="{name:'entrance-door-staller-stilo'}" active-class="none">
          <div class="entrance-door-series-img-container">
            <img class="door-series-img" src="../../assets/entrance-door/staller/stilo_1.webp" loading="lazy" alt="Входные двери СТАЛЛЕР СТИЛО ">
            <img class="door-series-img" src="../../assets/entrance-door/staller/stilo_2.webp" loading="lazy" alt="Входные двери СТАЛЛЕР СТИЛО ">
          </div>
          <div class="entrance-door-series-content-container">
            <h6 class="door-series-desc" ><span>от</span> 1628 руб</h6>
            <h6 class="door-series-desc" ><span></span> СТАЛЛЕР СТИЛО </h6>
          </div>
        </router-link>
      </div>
    </div>
    <ContactContainerMobile/>
  </div>
</template>

<script>
import EntranceDoorBack from "@/components/entrance-door/EntranceDoorBack";
import ContactContainerMobile from "@/components/mobile/mobileMenu/ContactContainerMobile";

export default {
  name: "EntranceDoorStaller",
  components: {EntranceDoorBack, ContactContainerMobile}
}
</script>

<style scoped>
.entrance-door-garda{
  width: 100%;
  display: flex;

  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-sizing: border-box;
}
.main-title{
  width: 100%;
  display: flex;
  justify-content: center;
}
.door-title{
  color: crimson;
  padding-bottom: 2rem;
}
.entrance-door-container{
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.entrance-door-series{
  width: 260px;
  height: 450px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
}
.entrance-door-series-img-container{
  width: 100%;
  display: flex;
  justify-content: center;
}
.door-series-img{
  width: 120px;
  height: auto;
}
.entrance-door-series-content-container{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
a{
  text-decoration: none;
  color: black;
}
</style>