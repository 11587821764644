<template>
  <div class="entrance-door-garda">
        <div class="main-title">
          <h2 class="door-title"> Входные двери Гарда</h2>
        </div>
          <EntranceDoorBack/>
    <h5 style="text-align: center; background-color: #0b6efd; margin: 1rem; padding: .5rem; color: white;">Для большинства
      дверей цвет внутренней отделки имеет несколько вариантов. Уточняйте возможные варианты в интересующих вас моделях.
      Или проконсультируйтесь у нашего специалиста.</h5>

          <div class=" entrance-door-container container">
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-stroygost5'}" active-class="none">
                  <div class="entrance-door-series-img-container">
                    <img class="door-series-img" src="../../assets/entrance-door/garda/стройгост5ЗФметалл_1.jpg" loading="lazy" alt="Входные двери Гарда СТРОЙГОСТ 5 РФ">
                    <img class="door-series-img" src="../../assets/entrance-door/garda/стройгост5ЗФметалл_2.jpg" loading="lazy" alt="Входные двери Гарда СТРОЙГОСТ 5 РФ">
                  </div>
                  <div class="entrance-door-series-content-container">
                    <h6 class="door-series-desc" ><span>от</span> 340 руб</h6>
                    <h6 class="door-series-desc" ><span></span> ГАРДА СТРОЙГОСТ 5 РФ</h6>
                  </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-stroygost5met'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/stroygost5-met_1.jpg" loading="lazy" alt="Входные двери Гарда ГАРДА СТРОЙГОСТ 5 РФ Металл/Металл">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/stroygost5-met_2.jpg" loading="lazy" alt="Входные двери Гарда ГАРДА СТРОЙГОСТ 5 РФ Металл/Металл">
                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 356 руб</h6>
                  <h6 class="door-series-desc" ><span></span> ГАРДА СТРОЙГОСТ 5 РФ Металл/Металл</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-stroygost5syl'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/СТРОЙГОСТ5РФСЕРЕБРО_1.jpg" loading="lazy" alt="Входные двери Гарда ГАРДА СТРОЙГОСТ 5 РФ Металл/Металл">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/СТРОЙГОСТ5РФСЕРЕБРО_2.jpg" loading="lazy" alt="Входные двери Гарда ГАРДА СТРОЙГОСТ 5 РФ Металл/Металл">
                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 371 руб</h6>
                  <h6 class="door-series-desc" ><span></span> ГАРДА СТРОЙГОСТ 5 РФ СЕРЕБРО</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-8'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ВХОДНАЯДВЕРЬГАРДА8ММ_1.jpg" loading="lazy" alt="Входные двери Гарда ГАРДА 8">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ВХОДНАЯДВЕРЬГАРДА8ММ_2.jpg" loading="lazy" alt="Входные двери Гарда ГАРДА 8">

                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 549 руб</h6>
                  <h6 class="door-series-desc" ><span></span>ГАРДА 8 ММ</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-muar-8'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ГАРДАМУАР8_1.webp" loading="lazy" alt="Входные двери Гарда ГАРДА 8">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ГАРДАМУАР8_2.webp" loading="lazy" alt="Входные двери Гарда ГАРДА 8">

                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 561 руб</h6>
                  <h6 class="door-series-desc" ><span></span>ГАРДА МУАР 8 ММ</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-muar-6'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/МУАРЦАРГА6_1.webp" loading="lazy" alt="Входные двери Гарда ГАРДА 5">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/МУАРЦАРГА6_2.webp" loading="lazy" alt="Входные двери Гарда ГАРДА 5">

                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 615 руб</h6>
                  <h6 class="door-series-desc" ><span></span>ГАРДА МУАР ЦАРГА 6 ММ</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-muar-10'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ГАРДАМУАР10_1.webp" loading="lazy" alt="Входные двери Гарда ГАРДА 5">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ГАРДАМУАР10_3.webp" loading="lazy" alt="Входные двери Гарда ГАРДА 5">

                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 615 руб</h6>
                  <h6 class="door-series-desc" ><span></span>ГАРДА МУАР ЦАРГА 6 ММ</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-8-ins'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ГАРДА8ВНУТРЕННЕЕ_1.webp" loading="lazy" alt="Входные двери ГАРДА 8 ВНУТРЕННЕЕ">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ГАРДА8ВНУТРЕННЕЕ_2.webp" loading="lazy" alt="Входные двери ГАРДА 8 ВНУТРЕННЕЕ">

                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 675 руб</h6>
                  <h6 class="door-series-desc" ><span></span>ГАРДА 8 ВНУТРЕННЕЕ</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-muar-lazer'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/МУАРЦАРГАЛАЗЕР_1.webp" loading="lazy" alt="Входные двери ГАРДА МУАР ЦАРГА ЛАЗЕР">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/МУАРЦАРГАЛАЗЕР_2.webp" loading="lazy" alt="Входные двери ГАРДА МУАР ЦАРГА ЛАЗЕР">

                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 747 руб</h6>
                  <h6 class="door-series-desc" ><span></span>ГАРДА МУАР ЦАРГА ЛАЗЕР</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-chicago'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ЧИКАГОNEW_1.webp" loading="lazy" alt="Входные двери ГАРДА ЧИКАГО NEW">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ЧИКАГОNEW_2.webp" loading="lazy" alt="Входные двери ГАРДА ЧИКАГО NEW">

                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 788 руб</h6>
                  <h6 class="door-series-desc" ><span></span>ГАРДА ЧИКАГО NEW</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-9-sylver'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ГАРДА9СЕРЕБРО_1.webp" loading="lazy" alt="Входные двери ГАРДА 9 СЕРЕБРО ЦАРГА">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ГАРДА9СЕРЕБРО_2.webp" loading="lazy" alt="Входные двери ГАРДА 9 СЕРЕБРО ЦАРГА">

                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 845 руб</h6>
                  <h6 class="door-series-desc" ><span></span>ГАРДА 9 СЕРЕБРО ЦАРГА</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-9-sylver-mirror'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ГАРДА9СЕРЕБРОЗЕРКАЛО_1.webp" loading="lazy" alt="Входные двери ГАРДА 9 СЕРЕБРО ЗЕРКАЛО">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ГАРДА9СЕРЕБРОЗЕРКАЛО_2.webp" loading="lazy" alt="Входные двери ГАРДА 9 СЕРЕБРО ЗЕРКАЛО">

                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 866 руб</h6>
                  <h6 class="door-series-desc" ><span></span>ГАРДА 9 СЕРЕБРО ЗЕРКАЛО</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-barcelona'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/БАРСЕЛОНА_1.webp" loading="lazy" alt="Входные двери ГАРДА БАРСЕЛОНА ЦАРГА NEW">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/БАРСЕЛОНА_2.webp" loading="lazy" alt="Входные двери ГАРДА БАРСЕЛОНА ЦАРГА NEW">

                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 911 руб</h6>
                  <h6 class="door-series-desc" ><span></span>ГАРДА БАРСЕЛОНА ЦАРГА NEW</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-boston'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/Бостон_1.webp" loading="lazy" alt="Входные двери ГАРДА БОСТОН">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/Бостон_2.webp" loading="lazy" alt="Входные двери ГАРДА БОСТОН">

                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 1024 руб</h6>
                  <h6 class="door-series-desc" ><span></span>ГАРДА БОСТОН</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-florida'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/Флорида_1.webp" loading="lazy" alt="Входные двери ГАРДА ФЛОРИДА ЗЕРКАЛО ФАЦЕТ">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/Флорида_2.webp" loading="lazy" alt="Входные двери ГАРДА ФЛОРИДА ЗЕРКАЛО ФАЦЕТ">

                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 1063 руб</h6>
                  <h6 class="door-series-desc" ><span></span>ГАРДА ФЛОРИДА ЗЕРКАЛО ФАЦЕТ</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-duble'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ГардаДвойная_1.webp" loading="lazy" alt="Входные двери ГАРДА 7.5 (ДВУПОЛЬНАЯ)">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/ГардаДвойная_2.webp" loading="lazy" alt="Входные двери ГАРДА 7.5 (ДВУПОЛЬНАЯ)">

                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 1123 руб</h6>
                  <h6 class="door-series-desc" ><span></span>ГАРДА 7.5 (ДВУПОЛЬНАЯ)</h6>
                </div>
              </router-link>
            </div>
            <div class="entrance-door-series">
              <router-link  :to="{name:'entrance-door-garda-isoterma'}" active-class="none">
                <div class="entrance-door-series-img-container">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/isoterma_1.webp" loading="lazy" alt="Входные двери ISOTERMA">
                  <img class="door-series-img" src="../../assets/entrance-door/garda/isoterma_2.webp" loading="lazy" alt="Входные двери ISOTERMA">

                </div>
                <div class="entrance-door-series-content-container">
                  <h6 class="door-series-desc" ><span>от</span> 1165 руб</h6>
                  <h6 class="door-series-desc" ><span></span>ГАРДА ISOTERMA</h6>
                </div>
              </router-link>
            </div>
        </div>
<ContactContainerMobile/>
  </div>
</template>

<script>
import ContactContainerMobile from "@/components/mobile/mobileMenu/ContactContainerMobile";
import EntranceDoorBack from "@/components/entrance-door/EntranceDoorBack";
export default {
  name: "EntranceDoorGarda",
  components: {EntranceDoorBack, ContactContainerMobile}
}
</script>

<style scoped>
.entrance-door-garda{
  width: 100%;
  display: flex;

  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-sizing: border-box;
}
.main-title{
  width: 100%;
  display: flex;
  justify-content: center;
}
.door-title{
  color: crimson;
  padding-bottom: 2rem;
}
.entrance-door-container{
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.entrance-door-series{
  width: 260px;
  height: 450px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
}
.entrance-door-series-img-container{
  width: 100%;
  display: flex;
  justify-content: center;
}
.door-series-img{
  width: 120px;
  height: auto;
}
.entrance-door-series-content-container{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
a{
  text-decoration: none;
  color: black;
}
</style>