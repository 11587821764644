<template>
  <div class="container-fluid"   >
  <div class="the-second-line">
   <h2 style="padding-left: 2rem; padding-right: 2rem; text-align: center" >При заказе двух окон - маскитная сетка в подарок</h2>
  </div>
  </div>
</template>


<script>
export default {
  name: "RedLineSecond"
}
</script>

<style scoped>

.the-second-line{

  margin-top: 4rem;
  margin-bottom: 3rem;
  padding: 1rem;
  color: aliceblue;
  background-color: crimson;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>