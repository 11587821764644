<template>
 <div>
   <MainWrapper/>
 </div>
</template>

<script>
import MainWrapper from "@/components/MainWrapper";

export default {
  name: 'App',
  components: {
    MainWrapper
  }
}
</script>

<style>



</style>
